export const makeSlug = (title) => {
  var slug = "";
  // Change to lower case
  var titleLower = title.toLowerCase();
  // Letter "e"
  slug = titleLower.replace(/e|é|è|ẽ|ẻ|ẹ|ê|ế|ề|ễ|ể|ệ/gi, 'e');
  // Letter "a"
  slug = slug.replace(/a|á|à|ã|ả|ạ|ă|ắ|ằ|ẵ|ẳ|ặ|â|ấ|ầ|ẫ|ẩ|ậ/gi, 'a');
  // Letter "o"
  slug = slug.replace(/o|ó|ò|õ|ỏ|ọ|ô|ố|ồ|ỗ|ổ|ộ|ơ|ớ|ờ|ỡ|ở|ợ/gi, 'o');
  // Letter "u"
  slug = slug.replace(/u|ú|ù|ũ|ủ|ụ|ư|ứ|ừ|ữ|ử|ự/gi, 'u');
  // Letter "d"
  slug = slug.replace(/đ/gi, 'd');
  // Trim the last whitespace
  slug = slug.replace(/\s*$/g, '');
  // Change whitespace to "-"
  slug = slug.replace(/\s+/g, '-');

  return slug;
}

export function ValidarCPF(cpf){

	var lRetorno = true;

	if (cpf !== ""){
		
		var Soma;
		var Resto;
		var exp = /\.|\-/g
		var strCPF = cpf.toString().replace( exp, "" ); 

		Soma = 0;
		for (let i=1; i<=9; i++) 
			Soma = Soma + parseInt(strCPF.substring(i-1, i)) * (11 - i);
		
		Resto = (Soma * 10) % 11;
		
		if ((Resto == 10) || (Resto == 11))
			Resto = 0;
		
    lRetorno = (Resto == parseInt(strCPF.substring(9, 10)));
		
		if (lRetorno){

			Soma = 0;
			for (let i = 1; i <= 10; i++) 
				Soma = Soma + parseInt(strCPF.substring(i-1, i)) * (12 - i);

			Resto = (Soma * 10) % 11;
			
			if ((Resto == 10) || (Resto == 11)) 
				Resto = 0;
			
      lRetorno = (Resto == parseInt(strCPF.substring(10, 11) ) ) 
		}
	}
	
	return lRetorno;
}
