<template>
  <section id="renew">
    <div class="section">
      <div class="columns is-centered is-vcentered">
        <div v-if="showRegister" class="column is-two-fifths">
          <div class="lg-container">
            <div class="columns">
              <div class="column is-full">
                <div class="centralize" style="margin: 20px 0 25px 0">
                  <!-- <img src="@/assets/logotipo_green.png" alt="Escala 10" width="110" style="margin-bottom: 30px"> -->
                  <h1 class="title">CONFIRMAÇÃO DO CADASTRO</h1>
                  <p class="subtitle" style="text-align: center">
                    Confirme seus dados cadastrais para realizar a renovação da
                    assintura.
                  </p>
                </div>

                <b-steps v-model="activeStep">
                  <b-step-item label="Sobre" icon="account" type="is-success">
                    <div class="itens-tab">
                      <b-field label="Nome">
                        <b-input
                          ref="nome"
                          v-model="cadUser.nome"
                          @blur="validForm('nome')"
                          maxlength="40"
                          pattern="[A-Za-zÀ-ú\s]{1,40}"
                          required
                          validation-message="40 Caracteres permitidos: A-Z, a-z, À-ú, espaços"
                        ></b-input>
                      </b-field>
                      <b-field label="Sobrenome">
                        <b-input
                          ref="sobrenome"
                          v-model="cadUser.sobrenome"
                          @blur="validForm('sobrenome')"
                          maxlength="80"
                          pattern="[A-Za-zÀ-ú\s]{1,80}"
                          required
                          validation-message="80 Caracteres permitidos: A-Z, a-z, À-ú, espaços"
                        ></b-input>
                      </b-field>
                      <b-field label="CPF">
                        <template slot="label">
                          <p>CPF</p>
                          <span style="font-size: 0.7rem"
                            >(necessário para transações bancárias em
                            Ligas)</span
                          >
                        </template>
                        <b-input
                          ref="cpf"
                          v-model="cadUser.cpf"
                          @blur="validForm('cpf')"
                          maxlength="11"
                          pattern="[0-9]{11}"
                          required
                          validation-message="Apenas números sem formatação com 11 digitos"
                        ></b-input>
                      </b-field>
                      <b-field label="Telefone">
                        <template slot="label">
                          <p>Telefone</p>
                          <span style="font-size: 0.7rem"
                            >(DDD + Número - apenas números sem
                            formatação)</span
                          >
                        </template>
                        <b-input
                          ref="telefone"
                          v-model="cadUser.telefone"
                          @blur="validForm('telefone')"
                          maxlength="11"
                          pattern="[0-9]{11}"
                          icon="whatsapp"
                          required
                          validation-message="Apenas números sem formatação com 11 digitos"
                        ></b-input>
                      </b-field>
                      <b-field label="Email">
                        <b-input
                          ref="email"
                          type="email"
                          v-model="cadUser.email"
                          @blur="validForm('email')"
                          required
                          @input="formatField('cadUser.email')"
                          pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                        ></b-input>
                      </b-field>
                    </div>
                  </b-step-item>

                  <b-step-item
                    label="Localização"
                    icon="account-plus"
                    type="is-info"
                  >
                    <div class="itens-tab">
                      <b-field label="CEP">
                        <template slot="label">
                          <p>CEP</p>
                          <span style="font-size: 0.7rem"
                            >(Caso o CEP seja localizado, alguns campos serão
                            preenchidos automaticamente)</span
                          >
                        </template>
                        <b-input
                          ref="cep"
                          v-model="cadUser.endereco.cep"
                          @blur="validForm('cep')"
                          maxlength="8"
                          pattern="[0-9]{8}"
                          validation-message="Apenas números sem formatação com 8 digitos"
                        ></b-input>
                      </b-field>
                      <b-field label="Endereço">
                        <template slot="label">
                          <p>Endereço</p>
                          <span style="font-size: 0.7rem"
                            >(Campo limitado em 20 caracteres. Caso necessário,
                            abrevie o endereço)</span
                          >
                        </template>
                        <b-input
                          ref="logradouro"
                          v-model="cadUser.endereco.logradouro"
                          @blur="validForm('logradouro')"
                          maxlength="20"
                          pattern="[0-9A-Za-zÀ-ú\s]{1,20}"
                          validation-message="20 Caracteres permitidos: 0-9, A-Z, a-z, À-ú, espaços"
                        ></b-input>
                      </b-field>
                      <b-field label="Numero">
                        <b-input
                          ref="numero"
                          v-model="cadUser.endereco.numero"
                          @blur="validForm('numero')"
                          maxlength="6"
                          pattern="[0-9A-Za-zÀ-ú\s]{1,6}"
                          validation-message="6 Caracteres permitidos: 0-9, A-Z, a-z, À-ú, espaços"
                        ></b-input>
                      </b-field>
                      <b-field label="Complemento">
                        <b-input
                          ref="comp"
                          v-model="cadUser.endereco.comp"
                          @blur="validForm('complemento')"
                          maxlength="12"
                          pattern="[0-9A-Za-zÀ-ú\s]{0,12}"
                          validation-message="12 Caracteres permitidos: 0-9, A-Z, a-z, À-ú, espaços"
                        ></b-input>
                      </b-field>
                      <b-field label="Bairro">
                        <b-input
                          ref="bairro"
                          v-model="cadUser.endereco.bairro"
                          @blur="validForm('bairro')"
                          maxlength="30"
                          pattern="[0-9A-Za-zÀ-ú\s]{1,30}"
                          validation-message="30 Caracteres permitidos: 0-9, A-Z, a-z, À-ú, espaços"
                        ></b-input>
                      </b-field>
                      <b-field label="Cidade">
                        <b-input
                          ref="cidade"
                          v-model="cadUser.endereco.cidade"
                          @blur="validForm('cidade')"
                          maxlength="20"
                          pattern="[0-9A-Za-zÀ-ú\s]{1,20}"
                          required
                          validation-message="20 Caracteres permitidos: 0-9, A-Z, a-z, À-ú, espaços"
                        ></b-input>
                      </b-field>
                      <b-field label="Estado">
                        <b-select
                          ref="uf"
                          v-model="cadUser.endereco.uf"
                          placeholder="Selecione seu Estado"
                          expanded
                          required
                        >
                          <option
                            v-for="(estado, i) of states"
                            :key="i"
                            :value="estado"
                          >
                            {{ estado }}
                          </option>
                        </b-select>
                      </b-field>
                      <b-field label="País">
                        <b-input
                          ref="pais"
                          v-model="cadUser.endereco.pais"
                          @blur="validForm('pais')"
                          maxlength="20"
                          pattern="[A-Za-zÀ-ú\s]{1,20}"
                          required
                          validation-message="20 Caracteres permitidos: A-Z, a-z, À-ú, espaços"
                        ></b-input>
                      </b-field>
                    </div>
                    <b-field style="margin-top: 20px; float: right">
                      <b-button type="is-primary" @click="validForm()"
                        >Avançar</b-button
                      >
                    </b-field>
                  </b-step-item>
                  <!--                 
                  <b-step-item label="Dados Bancários" icon="bank" type="is-info">
                    <p class="subtitle" style="text-align: center">Informe seus dados bancários para serem utilizados no resgate de créditos em sua carteira vitual, caso paticipe de alguma liga. Estas informações não são obrigatórias, e poderão ser informadas no processo de resgate dos créditos.</p>

                    <div class="columns is-multiline">
                      <div class="column is-full">
                        <banks :default="cadUser.bank" v-on:select="cadUser.bank = $event.id"></banks>
                      </div>

                      <div class="column is-one-third">
                        <b-field label="Tipo de Conta">
                          <div class="columns is-multiline">
                            <div class="column is-full">
                              <b-radio ref="account_type_corrente" name="account_type" v-model="cadUser.account_type" native-value="corrente" >Conta-Corrente</b-radio>
                              <b-radio ref="account_type_poupanca" name="account_type" v-model="cadUser.account_type" native-value="poupanca" >Poupança</b-radio>
                              <b-radio ref="account_type_indefinida" name="account_type" v-model="cadUser.account_type" native-value="indefinida" >Indefinida</b-radio>
                            </div>
                          </div>
                        </b-field>
                      </div>

                      <div class="column is-one-third">
                        <b-field label="Agência">
                          <b-input ref="bank_branch" name="bank_branch" v-model="cadUser.bank_branch" @blur="validForm('bank_branch')" maxlength="8" validation-message="8 Caracteres permitidos"></b-input>
                        </b-field>
                      </div>

                      <div class="column is-one-third">
                        <b-field label="Conta">
                          <b-input ref="account" name="account" v-model="cadUser.account" @blur="validForm('account')" maxlength="18" validation-message="18 Caracteres permitidos"></b-input>
                        </b-field>
                      </div>
                    </div>

                    <b-field style="margin-top: 20px; float: right">
                      <b-button type="is-primary" @click="validForm()">Avançar</b-button>
                    </b-field>
                  </b-step-item>
 -->
                </b-steps>
              </div>
            </div>
          </div>
        </div>

        <div v-if="showSignature" class="column is-three-fifths">
          <div class="lg-container">
            <div class="level centralize">
              <h1 class="title">Renovação da Assinatura</h1>
              <h2 class="subtitle" style="text-align: center">
                Se sua assinatura expirou ou o seu pagamento não foi aprovado,
                selecione um novo plano de assinatura para ter o acesso ao
                conteúdo do nosso site de acordo com os períodos abaixo
              </h2>
              <b-field>
                <router-link to="/">
                  <b-button size="is-small" type="is-light" outlined
                    >Voltar a Pagina Principal</b-button
                  >
                </router-link>
                <b-button
                  size="is-small"
                  type="is-light"
                  v-on:click="showRegisterVodal('register')"
                  outlined
                  >Voltar ao Cadastro</b-button
                >
              </b-field>
            </div>
            <div class="content">
              <!-- <plans v-on:select="selectPlan($event)" :has-cupom="true" :cpf="cadUser.cpf" :email="cadUser.email" :defaultInfluencer="influencer" :defaultCupom="cupom"></plans> -->
              <payment
                product="Planos"
                :hasCupom="true"
                :cpf="cadUser.cpf"
                :email="cadUser.email"
                :defaultInfluencer="influencer"
                :defaultCupom="cupom"
              ></payment>
            </div>
          </div>
        </div>
        <!-- <div class="">
          <div v-if="confirmButtonPlans" class="lg-button centralize-row">
            <b-button type="is-light" @click="removePlan" outlined style="margin-right: 15px;">Cancelar</b-button>
            <b-button type="is-primary" class="pay-button-getnet" @click="confirmSignature()">Confirmar</b-button>
          </div>
        </div> -->
      </div>
    </div>
  </section>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";

import vSelect from "vue-select";
import mask from "vue-r-mask";
import Payment from "@/components/payment/Payment";

import { ValidarCPF } from "@/shared/Utils";

// import Planos from '@/components/payment/Plans'
// import Banks from '@/components/payment/Banks'

export default {
  components: {
    "v-select": vSelect,
    payment: Payment, //,
    //'plans': Planos
    // 'banks': Banks
  },
  directives: {
    mask: mask,
  },
  name: "Renew",
  data() {
    return {
      action: "",
      influencer: "",
      cupom: "",
      activeStep: null,
      showRegister: false,
      showSignature: false,
      // confirmButtonPlans: false,
      cadUser: {},
      getNet: {},
      selectedPlan: {},
      states: [
        "AC",
        "AL",
        "AM",
        "AP",
        "BA",
        "CE",
        "DF",
        "ES",
        "GO",
        "MA",
        "MT",
        "MS",
        "MG",
        "PA",
        "PB",
        "PR",
        "PE",
        "PI",
        "RJ",
        "RN",
        "RO",
        "RS",
        "RR",
        "SC",
        "SE",
        "SP",
        "TO",
      ],
    };
  },
  async created() {
    // this.confirmButtonPlans = false

    let loader = this.$loading.show();
    let auserid = this.$route.params.auserid;
    if (this.$route.query.action) {
      this.action = this.$route.query.action;
    }
    if (this.$route.query.cupom) {
      this.cupom = this.$route.query.cupom;
    }
    if (this.$route.query.influencer) {
      this.influencer = this.$route.query.influencer;
    }

    await this.setLogin(auserid);

    if (this.userInfo) {
      this.cadUser = Object.assign({}, this.userInfo);

      this.cadUser.plano = "";
      this.cadUser.planoDescricao = "";
      this.cadUser.planoValor = 0;
      this.cadUser.cupom = "";
      this.cadUser.referencia = "";

      if (
        this.action.indexOf("promotion") == -1 &&
        this.action.indexOf("influencer") == -1 &&
        this.action.indexOf("renovacao") == -1 &&
        this.action.indexOf("upgrade") == -1
      ) {
        this.$buefy.dialog.alert({
          title: "ATENÇÃO !",
          message:
            "<p>Se sua assinatura expirou, ou o seu pagamento não foi aprovado, ou possui um boleto pendente mas deseja utilizar outra forma de pagamento, confirme os seus dados e selecione um novo plano de assinatura para ter o acesso ao conteúdo do nosso site de acordo com os períodos desejados.</p><p>* Caso tenha efetuado um pagamento através de boleto, aguarde a confirmação de pagamento liberando o acesso, que será enviada para seu e-mail.</p>",
          type: "is-warning",
          hasIcon: true,
        });
      } else if (this.action.indexOf("upgrade") !== -1) {
        this.$buefy.dialog.alert({
          title: "ATENÇÃO !",
          message:
            "Confirme seus dados e adquira um novo plano para atualização da sua assinatura, liberando assim novas telas e funções.",
          type: "is-info",
          hasIcon: true,
        });
      }

      this.showRegisterVodal("register");

      loader.hide();
    } else {
      loader.hide();
      console.error("ERROR_REGISTER_USER");
      console.error(err);
      this.$buefy.dialog.alert({
        title: "ATENÇÃO !",
        message:
          "Não foi possível localizar seu cadastro para realizar a renovação da assinatura. Favor entrar em contato com o suporte enviando um e-mail para suporte10@escala10.com.br",
        type: "is-danger",
        hasIcon: true,
      });
    }
  },
  computed: {
    ...mapGetters(["loggedIn", "userInfo", "userId", "userValid"]),
  },
  methods: {
    ...mapActions([
      "setLogin",
      "getUser",
      "addUser",
      "updateUser",
      "updateSignature",
      "getValidUser",
    ]),
    showRegisterVodal: async function (form) {
      this.showRegister = false;
      this.showSignature = false;
      // this.confirmButtonPlans = false

      switch (form) {
        case "register":
          this.showRegister = true;
          break;

        case "signature":
          let loader = this.$loading.show();

          try {
            await this.register();
            if (this.loggedIn) {
              this.cadUser.plano = "";
              this.cadUser.planoDescricao = "";
              this.cadUser.planoValor = 0;
              this.cadUser.cupom = "";
              this.cadUser.referencia = "";

              this.showSignature = true;
            }
            loader.hide();
          } catch (err) {
            loader.hide();
            console.error("ERROR_REGISTER_USER");
            console.error(err);
            this.$buefy.dialog.alert({
              title: "ATENÇÃO !",
              message:
                "Não foi possível atualizar seu cadastro. Favor entrar em contato com o suporte enviando um e-mail para suporte10@escala10.com.br",
              type: "is-danger",
              hasIcon: true,
            });
          }
          break;

        default:
          this.showLogin = true;
          break;
      }

      return true;
    },
    formatField: async function (field) {
      switch (field) {
        case "cadUser.email":
          this.cadUser.email = this.cadUser.email.toLowerCase();
          this.cadUser.email = this.cadUser.email.replace(/ /g, "");
          break;
      }
    },
    validForm: async function (field) {
      let valid = true;

      switch (field) {
        case "nome":
          this.cadUser.nome = this.cadUser.nome ? this.cadUser.nome.trim() : "";
          valid = this.$refs.nome.isValid;
          break;
        case "sobrenome":
          this.cadUser.sobrenome = this.cadUser.sobrenome
            ? this.cadUser.sobrenome.trim()
            : "";
          valid = this.$refs.sobrenome.isValid;
          break;

        case "cpf":
          this.cadUser.cpf = this.cadUser.cpf ? this.cadUser.cpf.trim() : "";

          if (await !ValidarCPF(this.cadUser.cpf)) {
            valid = false;

            this.$buefy.dialog.alert({
              title: "ATENÇÃO!",
              message: "CPF inválido.",
              type: "is-warning",
              hasIcon: true,
            });
          } else {
            await this.getValidUser(this.cadUser);

            if (this.userValid) {
              if (
                !this.userValid.available &&
                this.userValid.id != this.cadUser.id
              ) {
                let msg = "";
                valid = false;
                // if (this.userValid.email) {
                //   msg = "CPF já cadastrado em nosso site com o seguinte e-mail de login: "+this.userValid.email+"."
                // } else {
                msg = "CPF já cadastrado em nosso site.";
                // }
                msg +=
                  "<br>* Favor realizar o login para ter acesso ao site. Caso não lembre suas credenciais, execute o processo de recuperação de senha através do link 'Esqueci minha senha' para que possamos ajudá-lo a recupera-la.";
                meg +=
                  "<br>* Caso tenha expirado seu acesso, favor realizar o login para que possa efetuar a renovação do seu plano de acesso.";
                this.$buefy.dialog.alert({
                  title: "ATENÇÃO !",
                  message: msg,
                  type: "is-warning",
                  hasIcon: true,
                });
              }
            }
          }
          if (valid) valid = this.$refs.cpf.isValid;
          break;

        case "telefone":
          this.cadUser.telefone = this.cadUser.telefone
            ? this.cadUser.telefone.trim()
            : "";
          valid = this.$refs.telefone.isValid;
          break;

        case "email":
          await this.getValidUser(this.cadUser);

          this.cadUser.email = this.cadUser.email
            ? this.cadUser.email.trim()
            : "";

          if (this.userValid) {
            if (
              !this.userValid.available &&
              this.userValid.id != this.cadUser.id
            ) {
              let msg = "";
              valid = false;
              // if (this.userValid.cpf) {
              //   msg = "E-Mail já cadastrado em nosso site com o seguinte CPF: "+this.userValid.cpf+"."
              // } else {
              msg = "E-Mail já cadastrado em nosso site.";
              // }
              msg +=
                "<br>* Favor realizar o login para ter acesso ao site. Caso não lembre suas credenciais, execute o processo de recuperação de senha através do link 'Esqueci minha senha' para que possamos ajudá-lo a recupera-la.";
              msg +=
                "<br>* Caso tenha expirado seu acesso, favor realizar o login para que possa efetuar a renovação do seu plano de acesso.";
              this.$buefy.dialog.alert({
                title: "ATENÇÃO !",
                message: msg,
                type: "is-warning",
                hasIcon: true,
              });
            }
          }
          if (valid) valid = this.$refs.email.isValid;
          break;

        case "cep":
          this.cadUser.endereco.cep = this.cadUser.endereco.cep
            ? this.cadUser.endereco.cep.trim()
            : "";

          await this.$http
            .get(
              "https://apps.widenet.com.br/busca-cep/api/cep.json?code=" +
                this.cadUser.endereco.cep
            )
            .catch((err) => {
              console.error("HTTP_ERROR => " + err);
              throw new Error("ERROR_CEP");
            })
            .then(async (response) => {
              if (response) {
                let endereco = response.data;
                this.cadUser.endereco.logradouro = endereco.address;
                this.cadUser.endereco.bairro = endereco.district;
                this.cadUser.endereco.cidade = endereco.city;
                this.cadUser.endereco.uf = endereco.state;
              }
            });
          valid = this.$refs.cep.isValid;
          break;

        case "logradouro":
          this.cadUser.endereco.logradouro = this.cadUser.endereco
            ? this.cadUser.endereco.logradouro
              ? this.cadUser.endereco.logradouro.trim()
              : ""
            : "";
          valid = this.$refs.logradouro.isValid;
          break;
        case "numero":
          this.cadUser.endereco.numero = this.cadUser.endereco
            ? this.cadUser.endereco.numero
              ? this.cadUser.endereco.numero.trim()
              : ""
            : "";
          valid = this.$refs.numero.isValid;
          break;
        case "complemento":
          this.cadUser.endereco.comp = this.cadUser.endereco
            ? this.cadUser.endereco.comp
              ? this.cadUser.endereco.comp.trim()
              : ""
            : "";
          valid = this.$refs.comp.isValid;
          break;
        case "bairro":
          this.cadUser.endereco.bairro = this.cadUser.endereco
            ? this.cadUser.endereco.bairro
              ? this.cadUser.endereco.bairro.trim()
              : ""
            : "";
          valid = this.$refs.bairro.isValid;
          break;
        case "cidade":
          this.cadUser.endereco.cidade = this.cadUser.endereco
            ? this.cadUser.endereco.cidade
              ? this.cadUser.endereco.cidade.trim()
              : ""
            : "";
          valid = this.$refs.cidade.isValid;
          break;
        case "pais":
          this.cadUser.endereco.pais = this.cadUser.endereco
            ? this.cadUser.endereco.pais
              ? this.cadUser.endereco.pais.trim()
              : ""
            : "";
          valid = this.$refs.pais.isValid;
          break;
        // case 'bank_branch':
        //   this.cadUser.bank_branch = this.cadUser.bank_branch.trim()
        //   valid = this.$refs.bank_branch.isValid
        //   break;
        // case 'account':
        //   this.cadUser.account = this.cadUser.account.trim()
        //   valid = this.$refs.account.isValid
        //   break;

        case "plan":
          if (this.cadUser.plano === "") {
            valid = false;
            this.$buefy.dialog.alert({
              title: "ATENÇÃO !",
              message: "Favor selecionar um plano para confirmar a assinatura!",
              type: "is-warning",
              hasIcon: true,
            });
          }
          break;

        default:
          this.cadUser.nome = this.cadUser.nome ? this.cadUser.nome.trim() : "";
          this.cadUser.sobrenome = this.cadUser.sobrenome
            ? this.cadUser.sobrenome.trim()
            : "";
          this.cadUser.cpf = this.cadUser.cpf ? this.cadUser.cpf.trim() : "";
          this.cadUser.telefone = this.cadUser.telefone
            ? this.cadUser.telefone.trim()
            : "";
          this.cadUser.email = this.cadUser.email
            ? this.cadUser.email.trim()
            : "";
          this.cadUser.endereco.cep = this.cadUser.endereco
            ? this.cadUser.endereco.cep
              ? this.cadUser.endereco.cep.trim()
              : ""
            : "";
          this.cadUser.endereco.logradouro = this.cadUser.endereco
            ? this.cadUser.endereco.logradouro
              ? this.cadUser.endereco.logradouro.trim()
              : ""
            : "";
          this.cadUser.endereco.numero = this.cadUser.endereco
            ? this.cadUser.endereco.numero
              ? this.cadUser.endereco.numero.trim()
              : ""
            : "";
          this.cadUser.endereco.comp = this.cadUser.endereco
            ? this.cadUser.endereco.comp
              ? this.cadUser.endereco.comp.trim()
              : ""
            : "";
          this.cadUser.endereco.bairro = this.cadUser.endereco
            ? this.cadUser.endereco.bairro
              ? this.cadUser.endereco.bairro.trim()
              : ""
            : "";
          this.cadUser.endereco.cidade = this.cadUser.endereco
            ? this.cadUser.endereco.cidade
              ? this.cadUser.endereco.cidade.trim()
              : ""
            : "";
          this.cadUser.endereco.uf = this.cadUser.endereco
            ? this.cadUser.endereco.uf
              ? this.cadUser.endereco.uf.trim()
              : ""
            : "";
          this.cadUser.endereco.pais = this.cadUser.endereco
            ? this.cadUser.endereco.pais
              ? this.cadUser.endereco.pais.trim()
              : ""
            : "";

          // this.cadUser.bank_branch = this.cadUser.bank_branch.trim()
          // this.cadUser.account = this.cadUser.account.trim()

          this.$refs.nome.checkHtml5Validity();
          this.$refs.sobrenome.checkHtml5Validity();
          this.$refs.cpf.checkHtml5Validity();
          this.$refs.telefone.checkHtml5Validity();
          this.$refs.email.checkHtml5Validity();
          // campo inexistente na renovação
          // this.$refs.senha.checkHtml5Validity();
          this.$refs.cep.checkHtml5Validity();
          this.$refs.logradouro.checkHtml5Validity();
          this.$refs.numero.checkHtml5Validity();
          this.$refs.comp.checkHtml5Validity();
          this.$refs.bairro.checkHtml5Validity();
          this.$refs.cidade.checkHtml5Validity();
          this.$refs.pais.checkHtml5Validity();
          // this.$refs.bank_branch.checkHtml5Validity();
          // this.$refs.account.checkHtml5Validity();

          let msgAlert = "";

          if (!this.$refs.nome.isValid) {
            msgAlert += (msgAlert !== "" ? ", " : "") + "Nome";
          }
          if (!this.$refs.sobrenome.isValid) {
            msgAlert += (msgAlert !== "" ? ", " : "") + "Sobrenome";
          }
          if (!this.$refs.cpf.isValid) {
            msgAlert += (msgAlert !== "" ? ", " : "") + "CPF";
          }
          if (!this.$refs.telefone.isValid) {
            msgAlert += (msgAlert !== "" ? ", " : "") + "Telefone";
          }
          if (!this.$refs.email.isValid) {
            msgAlert += (msgAlert !== "" ? ", " : "") + "E-Mail";
          }
          // campo inexistente na renovação
          // if (!this.$refs.senha.isValid){
          //   msgAlert += ((msgAlert !== '') ? ', ' : '') + 'Senha'
          // }
          if (!this.$refs.cep.isValid) {
            msgAlert += (msgAlert !== "" ? ", " : "") + "CEP";
          }
          if (!this.$refs.logradouro.isValid) {
            msgAlert += (msgAlert !== "" ? ", " : "") + "Endereço";
          }
          if (!this.$refs.numero.isValid) {
            msgAlert += (msgAlert !== "" ? ", " : "") + "Número";
          }
          if (!this.$refs.bairro.isValid) {
            msgAlert += (msgAlert !== "" ? ", " : "") + "Bairro";
          }
          if (!this.$refs.cidade.isValid) {
            msgAlert += (msgAlert !== "" ? ", " : "") + "Cidade";
          }
          if (!this.$refs.uf.isValid) {
            msgAlert += (msgAlert !== "" ? ", " : "") + "UF";
          }
          if (!this.$refs.pais.isValid) {
            msgAlert += (msgAlert !== "" ? ", " : "") + "País";
          }
          // if (!this.$refs.bank_branch.isValid){
          //   msgAlert += ((msgAlert !== '') ? ', ' : '') + 'Agência'
          // }
          // if (!this.$refs.account.isValid){
          //   msgAlert += ((msgAlert !== '') ? ', ' : '') + 'Conta'
          // }
          if (msgAlert !== "") {
            msgAlert = "* Campo(s) com dados inconsistentes: " + msgAlert;
          }

          if (await !ValidarCPF(this.cadUser.cpf)) {
            msgAlert += (msgAlert !== "" ? "<br>" : "") + "* CPF inválido.";
          } else {
            await this.getValidUser(this.cadUser);

            if (this.userValid) {
              if (
                !this.userValid.available &&
                this.userValid.id != this.cadUser.id
              ) {
                msgAlert +=
                  (msgAlert !== "" ? "<br>" : "") +
                  "* CPF / E-Mail já cadastrados em nosso site.";
                msgAlert +=
                  (msgAlert !== "" ? "<br>" : "") +
                  "* Favor realizar o login com este e-mail para ter acesso ao site. Caso não lembre sua senha, favor clicar no link 'Esqueci minha senha' para que possamos ajudá-lo a recupera-la.";
                msgAlert +=
                  (msgAlert !== "" ? "<br>" : "") +
                  "* Caso tenha expirado seu acesso, favor realizar o login para que possa efetuar a renovação do seu plano de acesso.";
              }
            }
          }

          if (msgAlert !== "") {
            valid = false;
            this.$buefy.dialog.alert({
              title: "ATENÇÃO !",
              message: msgAlert,
              type: "is-warning",
              hasIcon: true,
            });
          } else {
            this.showRegisterVodal("signature");
          }

          break;
      }

      return valid;
    },
    register: async function () {
      try {
        if (this.loggedIn) {
          let error = await this.updateUser(this.cadUser);

          let msgError = "";
          if (error && error.length > 0) {
            let msgError = response.join("<br>");
          }

          if (msgError !== "") {
            this.$buefy.dialog.alert({
              title: "ATENÇÃO !",
              message: msgError,
              type: "is-danger",
              hasIcon: true,
            });
          } else {
            this.cadUser.id = this.userInfo.id;
            this.cadUser.token = this.userInfo.token;
          }
        } else {
          this.$buefy.dialog.alert({
            title: "ATENÇÃO !",
            message: "Não foi possível atualizar o cadastro. Tente novamente!",
            type: "is-danger",
            hasIcon: true,
          });
        }
      } catch (err) {
        console.error("ERROR_REGISTER");
        console.error(err);
        this.$buefy.dialog.alert({
          title: "ATENÇÃO !",
          message: "Não foi possível atualizar o cadastro. Tente novamente!",
          type: "is-danger",
          hasIcon: true,
        });
      }
    },
    // confirmSignature: async function () {

    //   let confirm = false

    //   if (await this.validForm('plan')) {

    //     let loader = this.$loading.show()

    //     await this.updateSignature(this.cadUser)
    //     if (this.loggedIn) {
    //       confirm = true
    //       let valor = 0.00
    //       valor = (this.selectedPlan.comDesconto) ? this.selectedPlan.cupom.valor : this.selectedPlan.valor
    //       if (valor <= 0) {
    //         this.$router.push({ path: '/painel/jogos' })
    //       }
    //     } else {
    //       console.error('ERROR_PAYMENT_REGISTER')
    //       console.error(err)
    //       this.$buefy.dialog.alert({
    //         title: "ATENÇÃO !",
    //         message: "Não foi possível registrar a assinatura. Tente novamente!",
    //         type: "is-danger",
    //         hasIcon: true
    //       });
    //     }

    //     loader.hide()
    //   }

    //   return confirm
    // },
    // async selectPlan (planSelect) {

    //   this.selectedPlan = planSelect

    //   if (planSelect){

    //     let valor = 0.00
    //     let cupom = ""
    //     let description = ""

    //     valor = (planSelect.comDesconto) ? planSelect.cupom.valor : planSelect.valor
    //     cupom = (planSelect.comDesconto) ? planSelect.cupom.cd_cupom : ""
    //     description = planSelect.descricao + ((cupom !== "") ? "-" + cupom : "")

    //     this.cadUser.plano = planSelect.codigo
    //     this.cadUser.planoDescricao = description
    //     this.cadUser.planoValor = valor
    //     this.cadUser.cupom = cupom
    //     this.cadUser.referencia = new Date().getTime()

    //     this.cadUser['status'] = ""
    //     this.cadUser['error_transaction'] = ""

    //     if (valor > 0) {
    //       await this.clearOnInitGetnet()
    //       await this.initGetnet()
    //     }

    //     // this.confirmButtonPlans = true

    //   } else {

    //     this.$buefy.dialog.alert({
    //       title: "ATENÇÃO !",
    //       message: "Favor selecionar um plano para prosseguir com a assinatura",
    //       type: "is-warning",
    //       hasIcon: true
    //     });
    //   }
    // },
    // clearOnInitGetnet() {

    //   var tagScript = document.querySelector('[data-getnet-sellerid]');
    //   var bodyContent = document.getElementsByTagName('body')[0];
    //   var buttonClass = '';

    //   if (!!tagScript) {
    //     buttonClass = tagScript.getAttribute('data-getnet-button-class') || '';
    //     bodyContent.removeChild(tagScript);
    //   }
    // },
    // async initGetnet() {

    //   let loader = this.$loading.show()

    //   this.getNet = {}

    //   await this.$http.get(url + '/payment/getnet/auth')
    //   .catch(err => {
    //     console.log(err)
    //   })
    //   .then( response => {
    //     if (response) {
    //       let getNetId = response.data.data
    //       if (getNetId){
    //         this.getNet["host"] = getNetId.host
    //         this.getNet["sellerId"] = getNetId.sellerId
    //         this.getNet["token"] = getNetId.type + ' ' + getNetId.access
    //         this.getNet["urlCheckout"] = getNetId.urlCheckout
    //       }
    //     }
    //   })

    //   if (this.getNet["token"]){

    //     var getnetIfrm = document.createElement('script')

    //     let expirationDate = new Date();
    //     expirationDate.setDate(expirationDate.getDate() + 1);
    //     let vencimentoBoleto = expirationDate.toLocaleDateString()

    //     this.getNet['amount'] = this.cadUser.planoValor.toFixed(2)
    //     this.getNet['customerid'] = this.cadUser.id.toString()
    //     this.getNet['orderid'] = this.cadUser.referencia.toString()
    //     this.getNet['customer_first_name'] = this.cadUser.nome
    //     this.getNet['customer_last_name'] = this.cadUser.sobrenome
    //     this.getNet['customer_document_number'] = this.cadUser.cpf
    //     this.getNet['customer_email'] = this.cadUser.email
    //     this.getNet['customer_address_street'] = this.cadUser.endereco.logradouro
    //     this.getNet['customer_address_street_number'] = this.cadUser.endereco.numero
    //     this.getNet['customer_address_complementary'] = this.cadUser.endereco.comp
    //     this.getNet['customer_address_neighborhood'] = this.cadUser.endereco.bairro
    //     this.getNet['customer_address_city'] = this.cadUser.endereco.cidade
    //     this.getNet['customer_address_state'] = this.cadUser.endereco.uf
    //     this.getNet['customer_address_zipcode'] = this.cadUser.endereco.cep
    //     this.getNet['customer_country'] = this.cadUser.endereco.pais
    //     this.getNet['items'] = '[{"name": "Escala 10","description": "'+this.cadUser.planoDescricao+'", "value": '+this.cadUser.planoValor.toFixed(2)+', "quantity": 1,"sku": "'+this.cadUser.plano+'"}]'
    //     this.getNet['url_callback'] = ""//url + "/signature/"+this.userId

    //     console.log(this.getNet)
    //     console.log(vencimentoBoleto)

    //     getnetIfrm.setAttribute("data-getnet-sellerid", this.getNet['sellerId'])
    //     getnetIfrm.setAttribute("data-getnet-token", this.getNet["token"])
    //     getnetIfrm.setAttribute("data-getnet-amount", this.getNet['amount'])
    //     getnetIfrm.setAttribute("data-getnet-customerid", this.getNet['customerid'])
    //     getnetIfrm.setAttribute("data-getnet-orderid", this.getNet['orderid'])
    //     getnetIfrm.setAttribute("data-getnet-button-class", "pay-button-getnet")
    //     getnetIfrm.setAttribute("data-getnet-installments", "1")
    //     getnetIfrm.setAttribute("data-getnet-boleto-expiration-date", vencimentoBoleto)
    //     getnetIfrm.setAttribute("data-getnet-customer-first-name", this.getNet['customer_first_name'])
    //     getnetIfrm.setAttribute("data-getnet-customer-last-name", this.getNet['customer_last_name'])
    //     getnetIfrm.setAttribute("data-getnet-customer-document-type", "CPF")
    //     getnetIfrm.setAttribute("data-getnet-customer-document-number", this.getNet['customer_document_number'])
    //     getnetIfrm.setAttribute("data-getnet-customer-email", this.getNet['customer_email'])
    //     getnetIfrm.setAttribute("data-getnet-customer-address-street", this.getNet['customer_address_street'])
    //     getnetIfrm.setAttribute("data-getnet-customer-address-street-number", this.getNet['customer_address_street_number'])
    //     getnetIfrm.setAttribute("data-getnet-customer-address-complementary", this.getNet['customer_address_complementary'])
    //     getnetIfrm.setAttribute("data-getnet-customer-address-neighborhood", this.getNet['customer_address_neighborhood'])
    //     getnetIfrm.setAttribute("data-getnet-customer-address-city", this.getNet['customer_address_city'])
    //     getnetIfrm.setAttribute("data-getnet-customer-address-state", this.getNet['customer_address_state'])
    //     getnetIfrm.setAttribute("data-getnet-customer-address-zipcode", this.getNet['customer_address_zipcode'])
    //     getnetIfrm.setAttribute("data-getnet-customer-country", this.getNet['customer_country'])
    //     getnetIfrm.setAttribute("data-getnet-items", this.getNet['items'])
    //     getnetIfrm.setAttribute("data-getnet-soft-descriptor", "Escala 10")
    //     getnetIfrm.setAttribute("data-getnet-url-callback", this.getNet['url_callback'])
    //     getnetIfrm.setAttribute("data-getnet-pre-authorization-credit", "")

    //     getnetIfrm.src = this.getNet["urlCheckout"]

    //     var oLogin = this

    //     getnetIfrm.addEventListener('load', ev => {
    //       // Funções compatíveis com IE e outros navegadores
    //       var eventMethod = (window.addEventListener ? 'addEventListener' : 'attachEvent');
    //       var eventer = window[eventMethod];
    //       var messageEvent = (eventMethod === 'attachEvent') ? 'onmessage' : 'message';

    //       // Ouvindo o evento do loader
    //       eventer(messageEvent, async function iframeMessage(e) {

    //         var data = e.data || '';

    //         if (data.status || data) {

    //           let status = (data.status) ? data.status : data

    //           if ("success,pending,error".indexOf(status) !== -1){

    //             oLogin.cadUser['status'] = status
    //             oLogin.cadUser['error_transaction'] = ""
    //             if (oLogin.cadUser['status'] == 'error'){
    //               oLogin.cadUser['error_transaction'] = (data.detail.message) ? data.detail.message : ''
    //             }

    //             await oLogin.updateSignature(oLogin.cadUser)
    //             if (!oLogin.loggedIn) {
    //               console.error('ERROR_PAYMENT_STATUS_UPDATE')
    //               console.error(err)
    //               oLogin.$buefy.dialog.alert({
    //                 title: "ATENÇÃO !",
    //                 message: "Não foi possível atualizar o status da transação! Favor entrar em contato com o suporte para verificar se o processo de assinatura foi registrado corretamente.",
    //                 type: "is-danger",
    //                 hasIcon: true
    //               });
    //             } else {

    //               if (status == 'success' || status == 'pending'){

    //                 oLogin.$router.push({ path: '/signature/'+oLogin.userId })

    //               } else if (status == 'error'){

    //                 console.error('ERROR_PAYMENT_REGISTER')
    //                 console.error(data)
    //                 let msgErro = (data.detail.message) ? data.detail.message : ""
    //                 oLogin.$buefy.dialog.alert({
    //                   title: "ATENÇÃO !",
    //                   message: "Não foi possível registrar a assinatura, pois a transação não foi finalizada com sucesso. "+msgErro,
    //                   type: "is-danger",
    //                   hasIcon: true
    //                 });
    //               }
    //             }
    //           }
    //         }
    //       }, false);
    //     });

    //     document.getElementsByTagName('body')[0].appendChild(getnetIfrm);
    //   }

    //   loader.hide()
    // },
    // removePlan () {
    //   this.cadUser.plano = 0
    //   // this.confirmButtonPlans = false
    // }
  },
};
</script>

<style scoped>
#renew {
  height: 100%;
  width: 100%;
  overflow-y: auto;
}
.lg-container {
  position: relative;
  padding: 5px;
  background-color: #3c3c3c;
  border-radius: 7px;
}
.lg-button {
  position: fixed;
  padding: 25px 0;
  background-color: #1a1a1a;
  border-top: 5px solid #9417fa;
  bottom: 0;
  left: 20.6%;
  width: calc(60% - 1.74rem);
  z-index: 2;
}
.lg-content {
  padding: 1rem;
  border-radius: 0 0 7px 7px;
}
.itens-tab {
  padding: 20px 0 30px 0;
}
.light {
  background-color: #4d4d4d;
}
@media (min-width: 320px) and (max-width: 1024px) {
  #renew {
    background-color: #3c3c3c;
  }
  .lg-button {
    width: calc(100% - 3px) !important;
    left: 0 !important;
  }
}

@media (min-width: 320px) and (max-width: 1024px) {
  .container,
  .container-register,
  .container-signature {
    width: calc(100% - 10px) !important;
  }
  .container {
    padding: 0 !important;
  }
  .container-login {
    width: calc(100% - 30px) !important;
  }
  .full-content .form-data {
    width: calc(100% - 40px) !important;
  }
  .half-content .form-data {
    width: calc(100% - 40px) !important;
  }
  .three-content .form-data {
    width: calc(100% - 40px) !important;
  }

  .container-signature {
    padding-bottom: 100px !important;
  }
  .container-signature .buttons {
    width: 100% !important;
    right: 0 !important;
    bottom: 0 !important;
    padding: 5px 0;
    background-color: black;
  }

  .plan-signature {
    width: calc(100% - 40px) !important;
  }
}

#renewSign {
  height: 100%;
  width: 100%;
  overflow-y: auto;
  background-image: url("../assets/bg_escala10.png");
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
}
/* .title {
  font-size: 2.5em;
  font-weight: 400;
  text-align: center;
  color: white;
  margin-bottom: 0;
} */
.label {
  margin-top: 30px;
  font-size: 1em;
  color: white;
}
.sub-title {
  font-size: 1em;
  color: white;
  margin-top: 10px;
  text-align: center;
}
.container {
  display: table;
  width: -webkit-fill-available;
  height: auto;
  padding: 30px;
  border-radius: 20px;
  background-color: transparent /*#4d4d4d*/;
}

.container-login,
.container-register,
.container-signature {
  /*float: left;*/
  width: -webkit-fill-available;
  height: auto;
  padding: 5px;
  transition: 0.2s ease-in-out;
  z-index: 999;
}

.container .buttons {
  width: 100%;
  margin-top: 20px;
  text-align: center;
  align-content: center;
  align-items: center;
}

.container .buttons .btn {
  font-size: 1em;
}

.container-signature .buttons {
  position: fixed;
  width: auto;
  right: 20px;
  bottom: 20px;
}
.container-signature .buttons .bt-confirm {
  border-radius: 50px;
  padding: 20px 50px !important;
}
/*
.container-signature .buttons .btn {
  font-size: 2.0em;
}
*/
.pdr-15 {
  padding-right: 20px;
}

.full-content,
.half-content,
.three-content {
  width: 100%;
}
.full-content .form-data,
.half-content .form-data,
.three-content .form-data {
  display: inline-table;
  margin: 0px 20px;
}
.full-content .form-data {
  width: calc(100% - 40px);
}
.half-content .form-data {
  width: calc(49% - 40px);
}
.three-content .form-data {
  width: calc(33% - 40px);
}

.warning {
  color: yellow /*#f0c349*/;
}
.danger {
  color: red /*#f04949*/;
}
.success {
  color: green /*#78f049*/;
}

.container-signature .full-content {
  text-align: center;
}

.plan-signature {
  display: inline-block;
  cursor: pointer;
  margin: 20px;
  width: calc(30% - 40px);
  height: 350px;
  color: white;
  font-size: 1.5em;
  text-align: center;
  align-content: center;
  vertical-align: top;
  border-radius: 50px;
  background-color: rgba(0, 0, 0, 0.5);
  transform: scale(0.9, 0.9);
  transition: all 0.2s ease-in-out;
  /*background-image: linear-gradient(#8E2790, #6828F8);*/
}
.plan-signature:hover {
  transform: scale(1, 1);
}
.plan-selected {
  /*border: 10px solid #00fffd;*/
  transform: scale(1, 1);
  box-shadow: 0px 0px 10px 15px rgba(0, 255, 247, 1);
}
.plan-blocked {
  background-color: rgba(0, 0, 0, 0.2);
}
.saleSignature {
  height: 450px;
}
.blockSignature {
  position: absolute;
  top: 0px;
  width: 100%;
  height: 100%;
  font-size: 2em;
  font-weight: bolder;
  color: black;
  border-radius: 50px;
  background-color: rgba(0, 255, 247, 0.5);
}
.plan-title {
  width: auto;
  color: black;
  padding: 10px 0px;
  font-size: 1.3em;
  font-weight: bold;
  box-shadow: 0px 7px 15px #00fffd;
  border-radius: 40px 40px 0 0;
  background-color: #00fffd;
}
.plan-subtitle {
  width: auto;
  color: black;
  padding: 5px;
  margin: 30px 10px 0 10px;
  font-size: 0.6em;
  font-weight: bold;
  border-radius: 20px;
  background-color: #00fffd;
}
.plan-option {
  display: none;
}
.plan-description {
  font-size: 1.5em;
  font-weight: bold;
  margin: 5px;
}
.plan-obs {
  font-size: 0.5em;
}
.plan-value {
  font-size: 1.5em;
  font-weight: bold;
  margin: 10px;
}

.plan-cupons {
  width: auto;
}
.plan-cupom {
  position: relative;
  width: fit-content;
}
.cupom-desconto {
  width: fit-content;
  color: black;
  padding: 5px 20px;
  border-radius: 20px;
  background-color: #00fffd;
  z-index: 999;
}
.cupom-desconto .percentual-valor {
  font-size: 1.2em;
  font-weight: bold;
}
.cupom-desconto .percentual-texto {
  font-size: 0.4em;
  font-weight: bold;
}
.cupom-valor {
  position: relative;
  top: -30px;
  color: #00fffd;
  padding: 50px 20px;
  border-radius: 20px;
  background-color: #4d4d4d;
}
.cupom-valor .valor {
  font-size: 1.5em;
  font-weight: bolder;
}
.cupom-valor .descricao {
  font-size: 0.7em;
}
.cupom-valor .moeda {
  font-size: 0.5em;
}

.form-data.cupom {
  text-align: center;
}
.cupom label,
.cupom input,
.cupom button {
  width: min-content;
  margin: 10px;
}
</style>
